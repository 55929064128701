<template>
    <div class="content">
        <b-row>
            <b-col cols="1" style="margin-left:1rem" @click="back"> <img src="@/assets/assets/BackImage.png" alt="" ></b-col>
            <b-col><h2 style="margin-right: 4rem; font-family: Oswald">Tukar Poin</h2></b-col>
        </b-row>
        <b-card no-body id="bcard">
            <b-tabs card align="center" active-nav-item-class="toActive" nav-class="backgroundCol">
                <b-tab title-link-class="tab" title="Gift">
                    <div style="margin-top: 1rem" v-if="this.giftsTukar.length == 0">
                        <h3>Penukaran Gift tidak tersedia</h3>
                    </div>
                    <!-- <div style="margin-top: 1rem"><h3>penukaran gift tidak tersedia</h3></div> -->
                    <b-container v-for="item in giftsTukar" :key="item.kode" @click="tukarGift(item)">
                        <b-container class="bv-example-row" v-if="item.sisa_tukar!=0">
                            <b-row>
                                <img :src="item.foto" alt="" style=" max-height: 250px; object-fit:fill;">
                            </b-row>
                            <b-row>
                                <b-col cols="10">
                                    <b-row style="margin-top:0.5rem;">
                                        <p style="font-size: 1.25rem; text-align: left: margin-left:1rem;">{{item.judul}}</p>
                                    </b-row>
                                    <b-row>
                                        <p style="font-size: 0.75em; text-align: left; "> Berlaku dari: {{item.periode_awal}} s.d {{item.periode_akhir}}</p>
                                    </b-row>
                                </b-col>
                                <b-col cols="2">
                                    <b-row style="margin-top:1rem;">
                                        <p ><img src="@/assets/assets/CoinBelanja.png" alt="" class="coinIcon"> {{item.harga_poin}}</p>
                                    </b-row>
                                    <b-row>
                                        <p style="font-size:0.75rem;"> Stok : {{ item.stock }}</p>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-container>
                </b-tab>
                <b-tab title-link-class="tab" title="Voucher">
                    <div style="margin-top: 1rem" v-if="this.voucherTukar.length == 0">
                        <h3>Penukaran Voucher tidak tersedia</h3>
                    </div>
                    <!-- <div style="margin-top: 1rem"><h3>penukaran gift tidak tersedia</h3></div> -->
                    <b-container  class="bv-example-row" v-for="item in voucherTukar" :key="item.id" @click="tukarVoucher(item.id)">
                        <b-row>
                            <img :src="item.foto" alt="" style=" max-height: 250px; object-fit:fill;">
                        </b-row>
                        <b-row>
                            <b-col cols="10">
                                <b-row style="margin-top:0.5rem;">
                                    <p style="font-size: 1.25rem; text-align: left: margin-left:1rem;">{{item.judul}}</p>
                                </b-row>
                                <b-row>
                                    <p style="font-size: 0.75em; text-align: left; "> Berlaku dari: {{item.periode_awal}} s.d {{item.periode_akhir}}</p>
                                </b-row>
                            </b-col>
                            <b-col cols="2">
                                <b-row style="margin-top:1rem;">
                                    <p ><img src="@/assets/assets/CoinBelanja.png" alt="" class="coinIcon"> {{item.harga_poin}}</p>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import router from './router'
import dotenv from "dotenv"
import firebase from 'firebase/app'
import 'firebase/auth'
dotenv.config()

export default {
    name:"TukarPoin",
    created() { 
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "TukarPoin"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            })
                        }).catch(()=>{})
                        this.getGiftTukar(),
                        this.getVoucherTukar()
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
    },
    data() { 
        return {
            giftsTukar:[],
            voucherTukar:[]
        }
    },
  
    methods: {
        async getGiftTukar() {
            try {
                let usrData = JSON.parse(localStorage.getItem('apiData')).data
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                const response = await axios.get(process.env.VUE_APP_giftsTukar, { headers: {"Authorization": `Bearer ${usrDataToken.token}` }, params: { alias:usrData.alias, onperiode: 1 }})
                this.giftsTukar = response.data.data
                // console.log("gifts")
                // console.log(this.giftsTukar)
                for(let i=0; i<this.giftsTukar.length; i++) {
                    moment.locale("id")
                    if(moment(this.giftsTukar[i].periode_awal).format('LL').substring(0,2).includes(" ")){
                        this.giftsTukar[i].periode_awal = moment(this.giftsTukar[i].periode_awal).format('LL').substring(0,5) + " " + this.giftsTukar[i].periode_awal.substring(0,4)
                    } else {
                        this.giftsTukar[i].periode_awal = moment(this.giftsTukar[i].periode_awal).format('LL').substring(0,6) + " " + this.giftsTukar[i].periode_awal.substring(0,4)
                    }
                    if(moment(this.giftsTukar[i].periode_akhir).format('LL').substring(0,2).includes(" ")){
                        this.giftsTukar[i].periode_akhir = moment(this.giftsTukar[i].periode_akhir).format('LL').substring(0,5) + " " + this.giftsTukar[i].periode_akhir.substring(0,4)
                    } else {
                        this.giftsTukar[i].periode_akhir = moment(this.giftsTukar[i].periode_akhir).format('LL').substring(0,6) + " " + this.giftsTukar[i].periode_akhir.substring(0,4)
                    }
                }
            } catch (err) {
                console.error(err.message)
            }
        },
        async getVoucherTukar() {
            try {
                let usrData = JSON.parse(localStorage.getItem('apiData'))
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                const response = await axios.get(process.env.VUE_APP_vouchersTukar, { headers: {"Authorization": `Bearer ${usrDataToken.token}` }, params: { alias:usrData.alias, onperiode: 1 }})
                this.voucherTukar = response.data.data
                // console.log("vouchers")
                // console.log(this.voucherTukar)
                for(let i=0; i<this.voucherTukar.length; i++) {
                    moment.locale("id")
                    if(moment(this.voucherTukar[i].periode_awal).format('LL').substring(0,2).includes(" ")){
                        this.voucherTukar[i].periode_awal = moment(this.voucherTukar[i].periode_awal).format('LL').substring(0,5) + " " + this.voucherTukar[i].periode_awal.substring(0,4)
                    } else {
                        this.voucherTukar[i].periode_awal = moment(this.voucherTukar[i].periode_awal).format('LL').substring(0,6) + " " + this.voucherTukar[i].periode_awal.substring(0,4)
                    }
                    if(moment(this.voucherTukar[i].periode_akhir).format('LL').substring(0,2).includes(" ")){
                        this.voucherTukar[i].periode_akhir = moment(this.voucherTukar[i].periode_akhir).format('LL').substring(0,5) + " " + this.voucherTukar[i].periode_akhir.substring(0,4)
                    } else {
                        this.voucherTukar[i].periode_akhir = moment(this.voucherTukar[i].periode_akhir).format('LL').substring(0,6) + " " + this.voucherTukar[i].periode_akhir.substring(0,4)
                    }
                }
            } catch (err) {
                console.error(err.message)
            }
        },
        back(){
            this.$router.replace('Homepage')
        },
        tukarVoucher(kode) {
            router.push({
                name:'DetailTukarVoucher',
                params:{
                    items: this.voucherTukar,
                    kodeId: kode
                }
            })
        },
        tukarGift(gift) {
            router.push({
                name:'DetailTukarGift',
                params:{
                    gift: gift
                }
            })
        },
    }
}
</script>

<style scoped>
*{
    font-family: Oswald;
}
.container {
    width: 100%;
}
b-row {
    border: 1px solid grey;
}

.bv-example-row {
    border: 1px solid lightgrey;
    border-radius: 1rem;
    margin-bottom: 1rem;
    padding-top: 3%;
}
#voucherTitle {
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    text-align: center;
}
</style>
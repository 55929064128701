<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'
import axios from "axios"

window.addEventListener('resize', ()=>{
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

export default {
  name: 'App',
  mounted(){
    this.$watch(
        () => this.$route.params.id,
        (toParams, previousParams) => {
            if(this.toParams != previousParams){
                // window.location.reload()
                console.log(toParams)
                console.log(previousParams)
            }
        }
    )
    setInterval(() => {
      if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
          if (localStorage.getItem('apiDataToken') != ".") {
            let token = JSON.parse(localStorage.getItem('apiDataToken'))
            var y = moment(token.expirationTime)
            let CurrentDate = moment();
            let newCd = moment(CurrentDate)
            let momentCd = newCd
            let exp = y
            let ms = exp.diff(momentCd,'seconds')
            if(ms <= 0){
              firebase.auth().signOut()
              .then(() => {
                  localStorage.clear();
                  this.$router.replace({name : "Login"}).then(() => { 
                      this.$toast.open({
                          message: 'Session Anda Telah Habis, Harap Login Kembali !',
                          type: 'error',
                          position: 'top'
                      });
                      this.$router.go() }).catch(()=>{})
              }).catch((err) => {
                  console.log(err);
              });
            }
          } else {
            if(this.$route.path == "/" || this.$route.path == "/Login") {
              localStorage.clear()
            }
          }
      } else {
        this.$router.replace({name : "Login"}).then(() => {
            this.$toast.open({
                message: 'Session Anda Sudah Berakhir, Harap Login Kembali !',
                type: 'error',
                position: 'top'
            });
        }).catch(()=>{})
      }
    }, 0)
  },
  data(){
    return {
      body:
        {
          grant_type: "refresh_token",
          refresh_token: ''
        },
    }
  },
  methods: {
    async getToken() {
        await axios.post(`https://securetoken.googleapis.com/v1/token`, this.body, {params: {key:'AIzaSyC1_wEUIiYMVs3l8FzY1ssj5pSRT3TkQJQ' }})
        .then((res)=>{
          console.log(res);
          localStorage.setItem("apiDataToken", JSON.stringify(res.data.id_token))
        })
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  max-width: 480px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>

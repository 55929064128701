<template>
    <div class="container">
        <!-- <b-alert class="alert" v-model="showDismissibleAlert" variant="success" dismissible>
                Data berhasil diupdate
        </b-alert>
        <b-alert class="alert" v-model="showDismissibleAlert2" variant="danger" dismissible>
                Data gagal diupdate, ada data yang masih kosong
        </b-alert>
        <b-alert class="alert" v-model="alertNIK" variant="danger" dismissible>
                Format NIK salah
        </b-alert> -->
        <!-- <b-alert class="alert" v-model="alertNomor" variant="danger" dismissible>
                Format nomor telepon salah
        </b-alert> -->
        <b-card>
            <b-tabs v-model="tabIndex" align="center" active-nav-item-class="toActive" nav-class="backgroundCol">
                <b-tab style="padding-top: 1rem;" title="Identitas">
                    <b-img v-if="blankImage=false" id="image" @change="previewImage" :src="data.gambar" rounded="circle"></b-img>
                    <b-img v-if="blankImage=true" id="blankImage" @change="previewImage" :src="data.gambar" rounded="circle"></b-img>
                    <b-col><input type="file" style="display: none;" ref="input" @change="previewImage" accept="image/*"></b-col>
                    <b-col v-if="stateUpload===1">
                        <p>Uploading: {{uploadValue.toFixed()+"%"}}
                            <progress :value="uploadValue" max="100"></progress>
                        </p>
                    </b-col> 
                    <b-row>
                        <b-col><b-btn id="btn" @click="click" variant="outline-primary">Choose Photo</b-btn></b-col>
                        <!-- <b-col><b-btn id="upload" @click="onUpload" variant="outline-primary">Upload</b-btn></b-col> -->
                    </b-row>
                    
                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="credit-card-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.ktp" placeholder="Masukkan Nomor KTP / SIM / Kartu Pelajar" maxlength="16" onkeypress="return event.charCode >= 48 && event.charCode <=57" :value="biodata.data.ktp" required></b-form-input></b-col>
                            </b-row>
                            <b-row v-if="alertNIK">
                                <p style="color:red;">Format NIK Salah !</p>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="person-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input v-model="data.nama" placeholder="Nama Lengkap" :value="biodata.data.nama"></b-form-input></b-col>
                            </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="6"><p id="JenisKelamin">Jenis Kelamin</p></b-col>
                                <b-col cols="3"><b-form-radio v-model="data.jns_kelamin" name="some-radios" value="L"><img src="../assets/assets/male-solid.png" alt="" style="width: 2rem;"></b-form-radio></b-col>
                                <b-col cols="3"><b-form-radio v-model="data.jns_kelamin" name="some-radios" value="P"><img src="../assets/assets/female-solid.png" alt="" style="width: 2rem;"></b-form-radio></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="geo-alt-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input v-model="data.tempat_lahir" placeholder="Tempat Lahir" :value="biodata.data.tempat_lahir"></b-form-input></b-col>
                            </b-row>  
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="calendar-date"></b-icon></b-col>
                                <!-- <b-form-datepicker id="tglLahir" v-model="data.tgl_lahir" :value="biodata.data.tgl_lahir" placeholder="Tanggal Lahir" value-type="format" format="YYYY-MM-DD"></b-form-datepicker> -->
                                <b-col cols="10">
                                    <date-picker id="datePicker" v-model="data.tgl_lahir" :masks='{ input:"YYYY-MM-DD"}' :model-config="modelConfig" 
                                    :available-dates='{
                                        start: new Date(new Date().getFullYear() - 70, 0, 1),
                                        end: new Date(new Date().getFullYear() - 15, 11, 31)
                                    }'>
                                        <template #default="{ inputValue, inputEvents }">
                                            <b-form-input  :value="inputValue" v-on="inputEvents" />
                                        </template>
                                    </date-picker>
                                </b-col>
                            </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="person-fill"></b-icon></b-col>
                                <!-- <b-col cols="10"><b-form-input placeholder="Agama" :value="this.agama"></b-form-input></b-col> -->
                                <b-col cols="10">
                                    <select name="agama_id" @change="onAgamaChange($event)" class="form-select form-control" v-model="data.agama">
                                        <option value="1">Islam</option>
                                        <option value="2">Katholik</option>
                                        <option value="3">Kristen</option>
                                        <option value="4">Buddha</option>
                                        <option value="5">Hindu</option>
                                        <option value="6">Kong Hu Cu</option>
                                        <option value="7">Lainnya</option>
                                    </select>
                                </b-col>
                            </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="briefcase"></b-icon></b-col>
                                <b-col cols="10">
                                    <select name="pekerjaan_id" @change="onPekerjaanChange($event)" class="form-select form-control" v-model="data.pekerjaan">
                                        <option value="1">Ibu Rumah Tangga</option>
                                        <option value="2">Profesional</option>
                                        <option value="3">TNI / POLRI</option>
                                        <option value="4">Guru / Dosen</option>
                                        <option value="5">Karyawan / Staff BUM Swasta</option>
                                        <option value="6">Buruh</option>
                                        <option value="7">Pensiunan / Purnawirawan</option>
                                        <option value="8">Pegawai Negri Sipil</option>
                                        <option value="9">Pelajar / Mahasiswa</option>
                                        <option value="10">Wiraswasta</option>
                                        <option value="11">Belum / Tidak Bekerja</option>
                                        <option value="12">Karyawan / Staff BUMN / BUMD</option>
                                        <option value="13">Pejabat Pemerintah</option>
                                        <option value="14">Rohaniawan</option>
                                        <option value="15">Seniman</option>
                                    </select>
                                </b-col>
                            </b-row>  
                        </div>
                    </div>
                    <b-button v-if="auth === 'true'" id="simpan" class="button" @click="putIdentitas">Simpan</b-button>
                    <b-button v-if="auth === 'false'" id="simpan" class="button" @click="cekIdentitas">Lanjut</b-button>
                    <b-button id="kembali" class="button" @click="kembali">Kembali</b-button> 
                </b-tab>
                <b-tab style="padding-top: 1rem;" title="Kontak" :disabled="stateKontak">
                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="envelope"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.email" placeholder="Email" :value="biodata.data.email" readonly></b-form-input></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="phone"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.ponsel" placeholder="Nomor Ponsel" :value="biodata.data.ponsel" minlength="11" maxlength="12" onkeypress="return event.charCode >= 48 && event.charCode <=57"></b-form-input></b-col>
                            </b-row>
                            <b-row v-if="alertNomor">
                                <p style="color:red;">Format Nomor Telepon Salah !</p>
                            </b-row>
                        </div>  
                    </div>
                    <b-button v-if="auth === 'true'" id="simpan" class="button" @click="putIdentitas">Simpan</b-button>
                    <b-button v-if="auth === 'false'" id="simpan" class="button" @click="cekKontak">Lanjut</b-button>
                    <b-button id="kembali" class="button" @click="tabIndex--">Kembali</b-button>
                </b-tab>
                <b-tab style="padding-top: 1rem;" title="Alamat" :disabled="stateAlamat">
                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="house"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.alamat" placeholder="Alamat Lengkap" :value="biodata.data.alamat"></b-form-input></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Provinsi</p></b-col>
                                <b-col cols="9">
                                    <select name="provinsi" @change="onProvinsiChange($event)" class="form-select form-control" v-model="provinsi" @click="getProvinsi">
                                        <option v-for="(prov, i) in dataProvinsi" :key="i.id" :value=dataProvinsi[i].provinsi>{{dataProvinsi[i].provinsi}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kabupaten</p></b-col>
                                <b-col cols="9">
                                    <select name="kabupaten" @change="onKabupatenChange($event)" class="form-select form-control" v-model="kabupaten" @click="getKabupaten">
                                        <option v-for="(kab, i) in dataKabupaten" :key="i.id" :value=dataKabupaten[i].kabupaten>{{dataKabupaten[i].kabupaten}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kecamatan</p></b-col>
                                <b-col cols="9">
                                    <select name="kabupaten" @change="onKecamatanChange($event)" class="form-select form-control" v-model="kecamatan" @click="getKecamatan">
                                        <option v-for="(kec, i) in dataKecamatan" :key="i.id" :value=dataKecamatan[i].kecamatan>{{dataKecamatan[i].kecamatan}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kelurahan</p></b-col>
                                <b-col cols="9">
                                    <select name="kelurahan" @change="onKelurahanChange($event)" class="form-select form-control" v-model="kelurahan" @click="getKelurahan">
                                        <option v-for="(kel, i) in dataKelurahan" :key="i.id" :value=dataKelurahan[i].kelurahan>{{dataKelurahan[i].kelurahan}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="house"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="kodepos" placeholder="Kode Pos" :value="kodepos" readonly></b-form-input></b-col>
                            </b-row>
                        </div>
                    </div>
                    <b-button v-if="auth === 'true'" id="simpan" class="button" @click="putIdentitas">Simpan</b-button>
                    <b-button v-if="auth === 'false'" id="simpan" class="button" @click="cekAlamat">Simpan</b-button>
                    <b-button id="kembali" class="button" @click="tabIndex--">Kembali</b-button>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import dotenv from "dotenv"
dotenv.config()

export default {
    name: 'EditProfile',
    components: {
        DatePicker
    },
    data(){
        return {
            biodata: [],
            data: {
                "user_update": '',
                "ktp": '',
                "nama":'',
                "alamat": '',
                "tempat_lahir": '',
                "tgl_lahir": '',
                "jns_kelamin": '',
                "agama": '',
                "pekerjaan": '',
                "ponsel": '',
                "email": '',
                "id_kodepos": 0,
                "gambar": '',
                "token_device": '',
                "firebase_uid": ''
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD'
            },
            kodepos:'',
            stateKontak:true,
            stateAlamat:true,

            dataProvinsi: [],
            dataKabupaten: [],
            dataKecamatan: [],
            dataKelurahan: [],

            provinsi: '',
            kabupaten: '',
            kecamatan: '',
            kelurahan: '',

            newUserEmail: '',
            blankImage: false,
            
            imageData: null,
            picture: null,
            uploadValue: 0,
            stateUpload: 0,

            // showDismissibleAlert: false,
            // showDismissibleAlert2: false,

            onAgamaChange(e) {
                this.data.agama = e.target.value
            },
            onPekerjaanChange(e) {
                this.data.pekerjaan = e.target.value
            },
            onProvinsiChange(e) {
                this.provinsi = e.target.value
            },
            onKabupatenChange(e) {
                this.kabupaten = e.target.value
            },
            onKecamatanChange(e) {
                this.kecamatan = e.target.value
            },
            onKelurahanChange(e) {
                this.kelurahan = e.target.value
            },

            tabIndex:0,
            auth:null,
            token:'',

            alertNIK: false,
            alertNomor: false
        }
    },
    created() {
        this.getBiodata()
        this.checkState()
        this.getToken()
        this.getProvinsi()
        this.getKabupaten()
        this.getKecamatan()
        this.getKelurahan()
        
    },
    mounted() {
        this.$nextTick(function () {
            window.setInterval(() => {
                this.nextToKontak();
                this.nextToAlamat();
                this.uploadState();
                this.getAuth()
            },100);
        })
    },
    methods: {
        uploadState() {
            if(this.uploadValue !== 0 && this.uploadValue !== 100){
                this.stateUpload = 1
            }else{
                this.stateUpload = 0
            }
        },
        click() {
            this.$refs.input.click()
        },
        previewImage(event) {
            this.uploadValue=0;
            this.picture=null;
            this.imageData=event.target.files[0];
            this.onUpload()
        },
        onUpload(){
            this.picture=null
            const storageRef = firebase.storage().ref(`data/user/0/id.amigogroup.member/cache/${this.imageData.name}`).put(this.imageData)
            storageRef.on(`state_changed`, snapshot => {
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error => {console.log(error.message)},
                () => {this.uploadValue=100;
                storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                    this.picture=url
                    this.data.gambar=url
                    let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
                    // eslint-disable-next-line no-unused-vars
                    const res = axios.put(`${process.env.VUE_APP_profiles}${this.data.user_update}`, this.data, { headers: {"Authorization" : `Bearer ${userToken.token}`} })
                    // eslint-disable-next-line no-unused-vars
                    .then(res =>{
                        localStorage.setItem("apiData", JSON.stringify(res))
                        this.$toast.open({
                            message: 'Data Berhasil Diupdate !',
                            type: 'success',
                            position: 'top'
                        });
                    }).catch((error) => {
                        if (error.request.status) {
                            this.$toast.open({
                                message: 'Data Gagal Diupdate !',
                                type: 'error',
                                position: 'top'
                            });
                        }
                    })
                })
                }
            )
        },
        getAuth() {
            this.auth = localStorage.getItem('auth')
             firebase.auth().onAuthStateChanged(
                (user) => {
                    if(user){
                        this.data.email = user.email
                        this.data.firebase_uid = user.uid
                    }
                }
            )
        },
        async getToken() {
            await firebase.auth().currentUser.getIdTokenResult().then(
                (token) => {
                    this.token = token.token;
            }).catch((err) => {
                console.log(err)
            })
        },
        getBiodata() {
            this.biodata = JSON.parse(localStorage.getItem("apiData"))
            this.data.user_update = this.biodata.data.alias
            this.data.ktp = this.biodata.data.ktp
            this.data.nama = this.biodata.data.nama
            this.data.jns_kelamin = this.biodata.data.jns_kelamin
            this.data.tempat_lahir = this.biodata.data.tempat_lahir
            this.data.tgl_lahir = this.biodata.data.tgl_lahir
            this.data.agama = this.biodata.data.agama
            this.data.pekerjaan = this.biodata.data.pekerjaan
            this.data.email = this.biodata.data.email
            this.data.ponsel = this.biodata.data.ponsel
            this.data.alamat = this.biodata.data.alamat
            this.data.gambar = this.biodata.data.gambar
            this.data.token_device = this.token
            this.kelurahan = this.biodata.data.kodepos.kelurahan
            this.kecamatan = this.biodata.data.kodepos.kecamatan
            this.kabupaten = this.biodata.data.kodepos.kabupaten
            this.provinsi = this.biodata.data.kodepos.provinsi
            this.kodepos = this.biodata.data.kodepos.kodepos

            if(this.biodata.data.id_kodepos === null || this.biodata.data.id_kodepos === ''){
                this.kodepos = ""
                this.kelurahan = ""
                this.kecamatan = ""
                this.kabupaten = ""
                this.provinsi = ""
            }

            if(this.data.gambar === ""){
                this.blankImage = true
            }else{
                this.blankImage = false
            }
        },
        kembali() {
            if(localStorage.getItem("auth")==='true'){
                this.$router.push({name : "Homepage"})
            }else{
                this.$router.push({name : "WelcomePage"})
            }
        },
        putIdentitas() {
            this.stateKontak = false
            this.stateAlamat = false
            if(localStorage.getItem("auth")==='true'){
                if(this.alertNIK !== true && this.data.ktp !== "" && this.data.nama !== "" && this.data.jns_kelamin !== "" && this.data.tempat_lahir !== "" && this.data.tgl_lahir !== "" && this.data.agama !== "" && this.data.pekerjaan !== "" && this.alertNomor !== true && this.data.ponsel !== "" && this.data.alamat !== ""){
                    let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
                    // eslint-disable-next-line no-unused-vars
                    const res = axios.put(`${process.env.VUE_APP_profiles}${this.data.user_update}`, this.data, { headers: {"Authorization" : `Bearer ${userToken.token}`} })
                    .then(() =>{
                        firebase.auth().onAuthStateChanged(
                            async (user) => {
                                if(user){
                                    const uid = user.uid
                                    const token = JSON.parse(localStorage.getItem('apiDataToken'))
                                    // eslint-disable-next-line no-unused-vars
                                    const res = await axios.get(`${process.env.VUE_APP_refresh}${uid}`, { headers: {"Authorization" : `Bearer ${token.token}`} })
                                    .then(res => {
                                        localStorage.setItem("apiData", JSON.stringify(res))
                                        this.$toast.open({
                                            message: 'Data Berhasil Diupdate !',
                                            type: 'success',
                                            position: 'top'
                                        });
                                    })
                                }
                            }
                        )
                    // eslint-disable-next-line no-unused-vars
                    }).catch((error) => {
                        this.$toast.open({
                            message: 'Data Gagal Diupdate !',
                            type: 'error',
                            position: 'top'
                        });
                    })
                }else{
                    this.$toast.open({
                        message: 'Data Gagal Diupdate !',
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },
        nextToKontak() {
            if(this.data.ktp !== "" && this.data.nama !== "" && this.data.jns_kelamin !== "" && this.data.tempat_lahir !== "" && this.data.tgl_lahir !== "" && this.data.agama !== "" && this.data.pekerjaan !== ""){
                if(this.data.ktp.length === 16){
                    this.stateKontak=false
                    this.alertNIK=false
                }else{
                    this.alertNIK=true
                    this.stateKontak=true
                    this.stateAlamat=true
                }
            }else{
                this.stateKontak=true
                this.stateAlamat=true
            }
        },
        nextToAlamat() {
            if(this.data.ponsel !== "" && this.data.ktp.length === 16 && this.data.nama !== ""){
                if(this.data.ponsel.length === 11 || this.data.ponsel.length === 12){
                    this.stateAlamat=false
                    this.alertNomor=false
                }else{
                    this.alertNomor=true
                    this.stateAlamat=true
                }
            }else{
                this.stateAlamat=true
            }
        },
        cekIdentitas() {
            this.tabIndex=1
        },
        cekKontak() {
            this.tabIndex=2
        },
        cekAlamat() {
            if(this.data.ktp !== "" && this.data.nama !== "" && this.data.jns_kelamin !== "" && this.data.tempat_lahir !== "" && this.data.tgl_lahir !== "" && this.data.agama !== "" && this.data.pekerjaan !== "" && this.data.ponsel !== "" && this.data.alamat !== ""){
                let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
                // eslint-disable-next-line no-unused-vars
                const res = axios.put(`${process.env.VUE_APP_profiles}${this.data.user_update}`, this.data, { headers: {"Authorization" : `Bearer ${userToken.token}`} })
                // eslint-disable-next-line no-unused-vars
                .then(res =>{
                    firebase.auth().onAuthStateChanged(
                        async (user) => {
                            if(user){
                                const uid = user.uid
                                const token = JSON.parse(localStorage.getItem('apiDataToken'))
                                // eslint-disable-next-line no-unused-vars
                                const res = await axios.get(`${process.env.VUE_APP_refresh}${uid}`, { headers: {"Authorization" : `Bearer ${token.token}`} })
                                .then(res => {
                                    localStorage.setItem("firebaseUID", JSON.stringify(uid))
                                    localStorage.setItem("tokenDevice", JSON.stringify(res.data.token_device))
                                    localStorage.setItem("apiData", JSON.stringify(res))
                                    this.$toast.open({
                                        message: 'Data Berhasil Diupdate !',
                                        type: 'success',
                                        position: 'top'
                                    });
                                    this.$router.replace({name : "Homepage"}).catch(()=>{})
                                })
                            }
                        }
                    )
                // eslint-disable-next-line no-unused-vars
                }).catch((error) => {
                    this.$toast.open({
                        message: 'Data Gagal Diupdate !',
                        type: 'error',
                        position: 'top'
                    });
                })
            }
        },
        checkState() {
            if(localStorage.getItem("auth")==='true'){
                this.stateKontak = false
                this.stateAlamat = false
            }
        },
        getProvinsi() {
            let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
            //eslint-disable-next-line no-unused-vars
            const res = axios.get(`${process.env.VUE_APP_editProvinsi}`,{ headers: {"Authorization" : `Bearer ${userToken.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataProvinsi = data.data
                }
            // eslint-disable-next-line no-unused-vars
            }).catch(() => {
            })
        },
        getKabupaten() {
            let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_editKabupaten}${this.provinsi}`,{ headers: {"Authorization" : `Bearer ${userToken.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKabupaten = data.data
                }
            }).catch(() => {
            })
        },
        getKecamatan() {
            let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_editKecamatan}${this.kabupaten}`,{ headers: {"Authorization" : `Bearer ${userToken.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKecamatan = data.data
                }
            }).catch(() => {
            })
        },
        getKelurahan() {
            let userToken = JSON.parse(localStorage.getItem("apiDataToken"))
            let array = 0
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_editKelurahan}${this.kecamatan}`,{ headers: {"Authorization" : `Bearer ${userToken.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKelurahan = data.data
                    for(let i = this.dataKelurahan.length-1; i>=0; i--){
                        if(this.dataKelurahan[i].kelurahan === this.kelurahan){
                            array = i
                        }
                    }
                    this.data.id_kodepos = this.dataKelurahan[array].id
                    this.kodepos = this.dataKelurahan[array].kodepos
                }
            }).catch(() => {
            })
        },
    }
}
</script>

<style scoped>
    header{
        margin-bottom: 10%;
    }
    b-form-input{
        margin-left: 1%;
    }
    .icon{
        font-size: 2rem;
        margin: 2%;
    }
    .container{
        position: relative;
        max-width: 100%;
        height: 100%;
        min-height: 0%;
        text-align: center;
    }
    .header1{
        color: white;
        background-color: #C12D31;
        padding: 2%;
        margin-bottom: 2%;
    }
    .header2{
        padding-bottom: 3%;
        border-bottom: 2px solid black;
    }
    .input{
        margin: 2%;
    }
    .gender{
        float: right;
    }
    .button{
        float: right;
        margin: 2%;
        padding: 1%;
    }
    #JenisKelamin{
        float: left;
        margin-top: 10%;
    }
    #back{
        margin-top: 1%;
        margin-left: 5%;
        position: absolute;
        left: 2%;
        color: white;
        font-size: 1.5rem;
    }
    #JenisKelamin{
        margin-top: 15%;
    }
    #simpan{
        background-color: #C12D31;
        border-style: none;
    }
    #kembali{
        color: black;
        background-color: transparent;
        border-style: none;
    }
    #image{
        width: 10rem;
        height: 10rem;
        padding-bottom: 0;
        object-fit: cover;
    }
    #blankImage{
        width: 10rem;
        height: 10rem;
        background: grey;
        padding-bottom: 0;
        object-fit: cover;
    }
    #btnUpload{
        border: transparent;
    }
    #btnUpload:hover{
        background-color: #C12D31;
    }
    #btn{
        margin: auto;
    }
    #upload{
        margin: auto;
    }
    #tglLahir{
        width: 100%;
    }
</style>
<template>
    <div class="InfoPromo" >
        <b-row class="tulisanPromo">
            <b-col class="judulPromo">
                <p class="tulisan">Info dan Promo</p>
            </b-col>
            <b-col class="LihatSemua">
                <div @click="routeListPromo">
                    <p  class="tulisan">Lihat Semua</p>
                </div>
                
            </b-col>
        </b-row>
        <b-row>
            <div class="sliderPromo">
                <slider ref="slider" :options="options" >
                    <slideritem v-for="(item,i) in DataInfoPromo" :key="i.id" >
                        
                            <img :src="item.foto_thumbnail" alt="" class="imagePromo" @click="sendDataSlider(item.kode)">
                        
                    </slideritem>
                </slider>
            </div>
        </b-row>
    </div>
</template>
   
<script>
import axios from "axios"
import { slider, slideritem } from 'vue-concise-slider'
import router from './router'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import dotenv from 'dotenv'
dotenv.config()

export default{
    name : "InfoPromo",
    components:{
        slider,slideritem
    },
    created(){
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "Homepage"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.getInfoPromo()
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
        
        // console.log(process.env.VUE_APP_voucherGift)
    },
    methods:{
        async getInfoPromo(){
            firebase.auth().onAuthStateChanged(
                // eslint-disable-next-line no-unused-vars
                async (user) => {
                    await firebase.auth().currentUser.getIdTokenResult().then(
                        async (token) => {
                            const response = await axios.get(process.env.VUE_APP_infoPromo,{headers: {"Authorization": `Bearer ${token.token}`}, params: {highlight: 1}})
                            this.DataInfoPromo = response.data.data
                            for(let i=0; i<this.DataInfoPromo.length; i++) {
                                moment.locale("id")
                                this.DataInfoPromo[i].periode_awal = moment(this.DataInfoPromo[i].periode_awal).format('LL')
                                this.DataInfoPromo[i].periode_akhir = moment(this.DataInfoPromo[i].periode_akhir).format('LL')
                            }
                    });
                }
            )
        },
        routeListPromo(){
            this.$router.replace('ListPromo')
        },
        sendDataSlider(idPromo){
            // this.$refs.slider.$emit('autoplayStop')
            router.push({
                name:'DetailPromo',
                params:{
                    items: this.DataInfoPromo,
                    kodeId: idPromo,
                    state: true,
                    from: 'slider'
                }
            })
            // this.$router.replace('DetailPromo')
        }
    },
    data(){
        return{
            DataInfoPromo: [],
            options:{
                autoplay: '5000',
                loopedSlide: 1,
                speed: 2000,
                loop:false,
                timingFunction: 'ease',
                slidesToScroll:1,
                centeredSlides: true,
                itemAnimation: true,
            },
            token: ''
        }
    }
}
</script>
   
<style scoped>
    .imagePromo{
        width: 100%; 
        height:17rem;
        border-radius: 2rem;
        
    }
    .sliderPromo{
        border-radius: 2rem;
        margin-top: -1rem;
        /* height: 18rem; */
        
        
    }
    .InfoPromo{
    margin-top: 2rem;
    font-family: Oswald;
    /* padding-bottom: 1rem; */
    
    }
    .tulisanPromo{
        height: 3rem;
        margin-bottom: 1.5rem;
        
    }
    .tulisan{
        margin-top: 1rem;
    }
    .slider-item {
        transform:scale(0.8);
        transition-timing-function: ease;
        transition-duration: 300ms;
    }
    .slider-item.slider-active {
        transform:scale(1.0);
        z-index: 999;
    }
    .slider-item.slider-active-copy {
        transform:scale(1.0);
        z-index: 999;
    }
</style>
<template>
<div style="position: relative;" class="container">
    <b-row >
        <b-col cols="1" class="kembali"  @click="Kembali()"> <img src="@/assets/assets/BackImage.png" alt="" class="iconKembali"></b-col>
        <!-- <b-col><h2 style="margin-right: 4rem">Detail Voucher</h2></b-col> -->
    </b-row>
    <div v-for="item in items" :key="item.id">
    <div v-if="item.id==kodeId">
        <img :src="item.foto" alt="" class="foto">
        <div class="description">
            <b-row>
                <b-col>
                    <h2 style="text-align: left; padding-top: .5rem; padding-bottom: .5rem;"> {{item.judul}} </h2>
                </b-col>
                <b-col id="setara">
                    <p style="color:#007476; font-weight:bold;"><img src="@/assets/assets/PoinMasuk.png" alt="" class="coinIcon"> {{item.harga_poin}}</p>
                </b-col>
            </b-row>
            <b-row>
                <p class="periode" style="font-size: 1.2rem; font-weight:600; color: grey">Periode {{item.periode_awal}} s.d {{item.periode_akhir}}</p>
            </b-row>
            <b-row>
                <h3 class="desc">Deskripsi</h3>
            </b-row>
            <b-row style="text-align: left; font-size: 1.5rem; padding-top: .5rem;">
                <p v-html="item.keterangan" style="margin-bottom: .25rem;"></p>
            </b-row>
            <b-row>
                <h3 class="desc">Cara Penukaran</h3>
                <div class="snk">
                    <ol>
                        <li>Klik Tombol tukar dibagian bawah halaman ini</li>
                        <li>Klik tombol 'Tukar' jika ingin menukarkan voucher</li>
                        <li>Gift atau voucher yang telah ditukar dapat dilihat di halaman 'Gift & Voucher'</li>
                    </ol>
                </div>
            </b-row>
            <b-row>
                <b-col style="padding:.5rem">
                    <h5>Poin Saya: <img src="@/assets/assets/PoinKeluar.png" alt="" class="coinIcon"> <span style="color:red">{{userData.poin}}</span></h5>
                </b-col>
                <b-col>
                    <button class="btnPakai" @click="Tukar(item, item.id)">
                        <h4>Tukar</h4>
                    </button>
                </b-col>
            </b-row>
        </div>
    </div>
    </div>
        
</div>
</template>

<script>
import axios from 'axios'
import dotenv from "dotenv"
dotenv.config()
export default {
    name: 'DetailTukarVoucher',
    props: ['items','kodeId'],
    created(){
        let usrdata = JSON.parse(localStorage.getItem("apiData"))
        this.userData= usrdata.data
    },
    methods:{
        Kembali(){
            this.$router.replace('TukarPoin')
        },
        async Tukar(voucher, idVoucher){
            console.log("tukar function")
            try {
                // eslint-disable-next-line no-unused-vars
                await axios.post(process.env.VUE_APP_tukarVoucher, { "voucher_id": idVoucher, "pelanggan_id_tukar": this.userData.alias, "user_create": voucher.user_create }).then( response => {
                    if (response.status == 200) {
                        this.$toast.open({
                            message:"Voucher berhasil ditukar!",
                            type: "success",
                            position: "top"
                        })
                        this.$router.replace({name: 'Vouchers', params: {tab: 1}})
                    } else {
                        this.$toast.open({
                            message:"Penukaran voucher gagal!",
                            type: "error",
                            position: "top"
                        })
                    }
                })
            } catch (error) {
                console.log(error.message)
            }
        }
    },
    data(){
        return{
            userData:[],
        }
    },
    mounted(){},
}
</script>

<style scoped>
.foto{
    width: 100%;
    margin-top: -2rem;
    z-index: -1;
    align-items: center;
}
.kembali{
    font-family: Oswald;
    z-index: 1;
    background-color: white;
    width: 3rem;
    border-radius: 1rem;
}
.iconKembali{
    width: 2rem;
}
.description{
    font-family: Oswald;
    margin-left: 0.5rem;
}
.periode{
    text-align: left;
}
.desc{
    text-align: left;
    font-weight: bold;
}
.btnPakai{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    bottom:0;
    height: 3rem;
}
.tokoBerlaku{
    /* margin-top: -1rem; */
    margin-bottom: 0.5rem;
}
.listToko{
    list-style-type: none;
    overflow: hidden;
    display: inline;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 50%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.vue-barcode-element{
    width: 3rem;
}
.judulBarcode{
    font-family: Oswald;
    text-align: center;
    font-weight: bold;
}
.snk{
    float: left;
    color: #707070;
    font-size: 1rem;
    text-align: left;
}
#setara{
    padding-top: .75rem;
    text-align: right;
}
</style>
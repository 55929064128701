<template>
  <div class="page">
      <div class="container">
          <div class="abs" @click="back">
              <img src="@/assets/assets/BackImage.png" alt="Back Button">
          </div>
          <b-img v-if="blankImage=false" id="image" :src="usrData.gambar" rounded="circle"></b-img>
          <b-img v-if="blankImage=true" id="blankImage" :src="usrData.gambar" rounded="circle"></b-img>
          <h3>{{usrData.nama}}</h3>
          <hr>
          <ul class="listOverlay">
            <li id="edit" @click="editProfile">Edit Profile</li>
            <li id="edit" v-b-modal.modal-center>Ganti Password</li>
            <b-modal id="modal-center" centered size="md" hide-footer hide-header>
                <GantiPassword />
            </b-modal>
            <!-- <li id="about">About</li> -->
            <li class="noborder">
                <button id="logout" @click="logout">
                    <!-- <img src="../assets/assets/edit_black_24dp 1.png" alt=""> -->
                    <span>Logout</span>
                </button>
            </li>
          </ul>
      </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import GantiPassword from './GantiPassword.vue'

export default {
    name: "Overlay",
    data() {
        return {
            usrData: [],
            blankImage: false,
        };
    },
    created() {
        this.loadLocalData();
    },
    methods: {
        loadLocalData() {
            let loadData = JSON.parse(localStorage.getItem("apiData"));
            this.usrData = loadData.data;
            if (this.usrData.gambar === "") {
                this.blankImage = true;
            }
            else {
                this.blankImage = false;
            }
        },
        back() {
            this.$router.replace("Homepage");
        },
        logout() {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Logout !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        },
        editProfile() {
            this.$router.push({ name: "EditProfile" });
        }
    },
    components: { GantiPassword }
}
</script>

<style scoped>
* {
    margin: auto;
}
.page {
    text-align: center;
}
.container {
    border: 1px solid black;
    text-align: center;
    padding: 2rem;
    margin-top: 2rem;
}
.container img {
    width: 15rem;
}
.container h3 {
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.listOverlay {
    list-style-type: none;
    padding: 0%;
    margin-top: 2rem;
    width: 100%;
}
li {
    border: 1px solid lightgrey;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
}
.noborder {
    border: none;
}
#logout {
    background-color: #C12D31;
    color: white;
    border-radius: 3rem;
    width: 100%;
    padding: .5rem;
    border: none;
    box-shadow: 5px 5px lightgrey;
}
.abs {
    position: absolute;
}
.abs img {
    width: 2rem;
}
#image{
    width: 10rem;
    height: 10rem;
    padding-bottom: 0;
    object-fit: cover;
}
#blankImage{
    width: 10rem;
    height: 10rem;
    background: grey;
    padding-bottom: 0;
    object-fit: cover;
}
</style>
<template>
    <div class="password">
        <b-row class="input">
            <h2>Lupa Password</h2>
            <p>Masukkan Email Anda untuk mengatur ulang password</p>
            <b-form-input id="Email" class="input" placeholder="Email" v-model="email" required></b-form-input>
        </b-row>
        <b-row class="buttonInput">
            <b-col cols="6">
                <b-button id="kembali" class="button" @click="kembali">Kembali</b-button>
            </b-col>
            <b-col cols="6">
                <b-button id="kirim" class="button" variant="danger" @click="lupaPassword">Kirim</b-button>
            </b-col> 
        </b-row>
        <!-- <b-row>
            <b-alert class="alert" v-model="showDismissibleAlert" variant="success" dismissible>
                Link reset password berhasil dikirimkan
            </b-alert>
            <b-alert class="alert" v-model="showDismissibleAlert2" variant="danger" dismissible>
                Link reset password gagal dikirimkan
            </b-alert>
        </b-row> -->
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
    name: 'LupaPassword',
    data(){
        return{
            email: '',
            text: '',
            // showDismissibleAlert: false,
            // showDismissibleAlert2: false
        }
    },
    methods: {
        kembali() {
            this.$root.$emit('methodBack')
        },
        lupaPassword() {
            firebase
                .auth()
                    .sendPasswordResetEmail(this.email).then(() => {
                        this.$toast.open({
                            message: 'Link Reset Password Berhasil Dikirimkan !',
                            type: 'success',
                            position: 'top'
                        });
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((request) => {
                        this.$toast.open({
                            message: 'Link Reset Password Gagal Dikirimkan !',
                            type: 'error',
                            position: 'top'
                        });
                    });
        },
    }
}
</script>

<style scoped>
    .password{
        background-image: white;
        max-width: 406px;
        height: 250px;
        min-height: 0%;
    }
    .input{
        color: black;
    }
    .buttonInput{
        margin-top: 2%;
    }
    #kirim{
        width: 100%;
    }
    #kembali{
        width: 100%;
    }
</style>
<template>
  <div style="position: relative;" class="container">
      <b-row >
        <b-col cols="1" class="kembali"  @click="Kembali(from)"> <img src="@/assets/assets/BackImage.png" alt="" class="iconKembali"></b-col>
        <!-- <b-col><h2 style="margin-right: 4rem">Detail Gift</h2></b-col> -->
      </b-row>
      <div v-for="item in detailVoucher" :key="item.kode">
      <div v-if="item.kode==kodeId">
        <img :src="item.foto" alt="" class="foto">
        <div class="description">
            <!-- Gift Title -->
            <!-- <p class="headers title">Judul</p> -->
            <b-row>
                <p class="periode" v-if="item.tgl_notif">Periode {{item.tgl_notif}} s.d {{item.periode_akhir}}</p>
                <p class="periode" v-else>Periode {{item.periode_awal}} s.d {{item.periode_akhir}}</p>
                <p class="periode" v-if="item.sisa" style="font-weight:bold;">Sisa Voucher: {{item.sisa}}</p>
            </b-row>
            <b-row>
                <h3 class="desc">Judul</h3>
            </b-row>
            <b-row>
                <p style="text-align: left; font-size: 1.25rem"> {{item.judul}} </p>
            </b-row>
            <b-row>
                <h3 class="desc">Berlaku di</h3>
            </b-row>
            <b-row class="tokoBerlaku" v-if="item.toko===null">
                <ul class="listToko" style="text-align:left">
                    <!-- <li style="float: left;" v-if="toko.kode_toko == '00'"> Klaten</li> -->
                    <li> Bimbo Delanggu</li>
                    <li> Granada Delanggu</li>
                    <li> Amigo Klaten</li>
                    <li> Dinasti Prambanan</li>
                    <li> Amigo Pedan</li>
                    <li> Amigo Sukoharjo</li>
                    <li> Amigo Boyolali</li>
                    <li> Amigo Wonosari</li>
                    <li> Amigo Karanganyar</li>
                </ul>
            </b-row>
            <b-row class="tokoBerlaku">
                <template v-for="item in detailVoucher">
                    <div v-for="toko in item.toko" :key="toko.id">
                        <div v-if="item.kode==kodeId"> 
                            <!-- <p class="" v-if="toko.kode_toko == '04'"> Granada |</p>
                            <p class="" v-if="toko.kode_toko == '03'"> Klaten |</p> -->
                            <ul class="listToko">
                                <!-- <li style="float: left;" v-if="toko.kode_toko == '00'"> Klaten</li> -->
                                <li style="float: left;" v-if="toko.kode_toko == '01'"> Bimbo Delanggu</li>
                                <li style="float: left;" v-if="toko.kode_toko == '02'"> Granada Delanggu</li>
                                <li style="float: left;" v-if="toko.kode_toko == '03'"> Amigo Klaten</li>
                                <li style="float: left;" v-if="toko.kode_toko == '04'"> Dinasti Prambanan</li>
                                <li style="float: left;" v-if="toko.kode_toko == '05'"> Amigo Pedan</li>
                                <li style="float: left;" v-if="toko.kode_toko == '06'"> Amigo Sukoharjo</li>
                                <li style="float: left;" v-if="toko.kode_toko == '07'"> Amigo Boyolali</li>
                                <li style="float: left;" v-if="toko.kode_toko == '08'"> Amigo Wonosari</li>
                                <li style="float: left;" v-if="toko.kode_toko == '09'"> Amigo Karanganyar</li>
                            </ul>
                        </div>
                    </div>
                </template>
            </b-row>
            <b-row>
                <h3 class="desc">Deskripsi</h3>
            </b-row>
            <b-row>
                <p v-html="item.keterangan" style="text-align: left"> </p>
            </b-row>
            <!-- <b-row>
                <h3 class="desc">Syarat & Ketentuan</h3>
                <div class="snk">
                    <ol>
                        <li>Menunjukan Amigo Member Apps</li>
                        <li>Minimal Transaksi Rp. {{item.minimal_belanja}}</li>
                        <li v-if="!item.minumal_belanja.length">Minimal Transaksi Rp. 0</li>
                    </ol>
                </div>
            </b-row> -->
            <b-row>
                <h3 class="desc">Cara Penukaran</h3>
                 <div class="snk">
                    <ol>
                        <li>Klik Tombol "Pakai Promo" dibagian bawah halaman ini</li>
                        <li>Tunjukan barcode untuk discan kasir</li>
                        <!-- <li v-if="!item.minumal_belanja.length">Minimal Transaksi Rp. 0</li> -->
                    </ol>
                </div>
            </b-row>
            <b-row v-if="!item.id_kontrak_merchant || item.tukar_di_amigo == 1 ">
                <b-col>
                    <button v-if="state && item.limit_tukar!=0" class="btnPakai" @click="pakaiPromo(item.kode)" v-b-modal="'modal-center'">
                   <h4>Pakai Promo</h4>
                </button>
                </b-col>
                <b-modal  id="modal-center" centered size="md" hide-footer hide-header>
                     <div class="container">
                         <b-row class="judulBarcode">
                             <h4>Tunjukan Barcode untuk di scan</h4>
                         </b-row>
                        <b-row style="text-align: center">
                                <barcode v-bind:value="kodeBarcode"
                                    font-size="20rem"
                                    height="70%"
                                    width=1.5
                                    format="CODE128" />
                        </b-row>
                        <b-row>
                            <button class="btnTutup" @click="$bvModal.hide('modal-center')">Tutup</button>
                        </b-row>
                    </div>
                </b-modal>
            </b-row>
            <b-row v-if="item.tukar_di_amigo == 0">
                <b-col>
                    <button v-if="state" class="btnPakai"  v-b-modal="'modal-center'">
                   <h4>Pakai Promo</h4>
                </button>
                </b-col>
                <b-modal  id="modal-center" centered size="md" hide-footer hide-header v-if="showModal">
                    <div class="password">
        <b-row class="input">
            <h2>Masukan Kode Validasi</h2>
            <b-col cols="12">
                
                <b-input-group>
                    <b-form-input id="Email" class="input" placeholder="Masukan Kode Validasi (Kode Merchant)" v-model="inputPinMerchant" :type="passwordFieldTypeBaru" required>
                        
                    </b-form-input>
                    <b-icon v-if="passwordFieldTypeBaru === 'password' " icon="eye" id="show" type="password" @click="switchVisibilityBaru"></b-icon>
                    <b-icon v-if="passwordFieldTypeBaru === 'text' " icon="eye-slash" id="show" type="password" @click="switchVisibilityBaru"></b-icon>
                </b-input-group>
                <b-row v-if="alertPin">
                    <p style="color:red;">Pin yang anda masukan salah</p>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <br>
        </b-row>
        <b-row class="buttonInput">
            <b-col cols="6"  >
                <b-button id="kembali" class="button" @click="$bvModal.hide('modal-center')">Batal</b-button>
            </b-col>
            <b-col cols="6" >
                <b-button id="kirim" class="button float-right" variant="danger" @click="kirim(item.kontrak_merchants.pin_merchant, item.kontrak_merchants.user_create,item.kode)">Kirim</b-button>
               
            </b-col> 
        </b-row>
    </div>
                </b-modal>
            </b-row>
        </div>
      </div>
      </div>
         
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import axios from "axios"
import dotenv from "dotenv"
dotenv.config()
// import moment from 'moment'
// import KonfirmasiMerchant from './konfirmasiMerchant.vue'
export default {
    components: { 'barcode':VueBarcode,  },
    name: 'DetailPromo',
    props: ['items','kodeId','state','from'],
    created(){
        this.getDetailVoucher()
    },
    methods:{
        async getDetailVoucher(){
            let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
            await axios.get(`${process.env.VUE_APP_detailVoucher}${this.kodeId}`, { headers: {"Authorization": `Bearer ${usrDataToken.token}` }}).then((response) => {
                this.detailVoucher = response.data
                console.log(this.detailVoucher);
            })
        },
        Kembali(from){
            if(from=='slider'){
                this.$router.replace('Homepage')
            }else if(from=='list'){
                this.$router.replace('ListPromo')
            }else{
                this.$router.replace('Vouchers')
            }
        },
        switchVisibilityBaru() {
            this.passwordFieldTypeBaru = this.passwordFieldTypeBaru === "password" ? "text" : "password";
        },
        kirim(pin, userCreate, kode){
            if(this.inputPinMerchant == pin){
                let userData = JSON.parse(localStorage.getItem("apiData"));
                const kodePelanggan = userData.data.alias;
                try{
                    // eslint-disable-next-line no-unused-vars
                    const response = axios.post(process.env.VUE_APP_kirimPromo,  {kode_promo: kode,user_create: userCreate, pelanggan_id: kodePelanggan}).then(response=>{
                         this.$toast.open({
                        message: 'Voucher Berhasil Ditukar',
                        type: 'success',
                        position: 'top'
                    });
                     this.showModal = false
                    });
                }catch(err){
                    console.log(err);
                }
                
            }else{
                this.alertPin = true;
            }
        },
        hideModal() {
           this.$root.$emit('bv::hide::modal-center')
        },
       
        pakaiPromo(kodeP){
            let userData = JSON.parse(localStorage.getItem("apiData"));
            const kodePelanggan = userData.data.alias;
            const kodePromo = kodeP;
            if(kodePromo.length > 6){
                this.kodeBarcode = kodePromo
                //console.log(this.kodeBarcode)
            }else{
                 this.kodeBarcode = kodePromo+kodePelanggan;
                 const jumlahHuruf =this.kodeBarcode.length
                if(jumlahHuruf == 14){
                // console.log(this.kodeBarcode)
                }else{
                // let newKode = this.kodeBarcode;
                    for (let index = 0; index < 14-jumlahHuruf; index++) {
                        this.kodeBarcode += '#'
                    }
                // console.log(this.kodeBarcode)
                }
            }
        },
        showBarcode() {
            console.log("Barcode Shown")
        },
        changeDate(array){
            for(let i = 0; i<array.length;i++){
                // moment.locale('id')
                // array[i].periode_awal = moment(array[i].periode_awal).format('LL')
                // array[i].periode_akhir = moment(array[i].periode_akhir).format('LL')
            }
        }
    },
    data(){
        return{
            kodeBarcode: '',
            showModal: true,
            pinMerchant: '',
            inputPinMerchant: '',
            passwordFieldTypeBaru: "password",
            alertPin: false,
            detailVoucher: []
        }
    },
    mounted(){
        this.changeDate(this.items)
    },
   
}
</script>

<style scoped>
.foto{
    width: 100%;
    margin-top: -2rem;
    z-index: -1;
    align-items: center;
}
.kembali{
    font-family: Oswald;
    z-index: 1;
    background-color: white;
    width: 3rem;
    border-radius: 1rem;
}
.iconKembali{
    width: 2rem;
}
.description{
    font-family: Oswald;
    margin-left: 0.5rem;
}
.periode{
    text-align: left;
}
.desc{
    text-align: left;
    font-weight: bold;
}
.btnPakai{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    bottom:0;
    height: 3rem;
}
.tokoBerlaku{
    /* margin-top: -1rem; */
    margin-bottom: 0.5rem;
}
.listToko{
    list-style-type: none;
    overflow: hidden;
    display: inline;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
/* .vue-barcode-element{
    width: 3rem;
} */
.judulBarcode{
    font-family: Oswald;
    text-align: center;
    font-weight: bold;
}
.snk{
    float: left;
    color: #707070;
    font-size: 1rem;
    text-align: left;
}
#show{
        margin: auto;
        margin-left: 0.5rem;
        font-size: 1.5rem;
}

</style>
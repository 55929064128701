<template>
    <div class="password">
        <b-row class="input">
            <h2>Ganti Password</h2>
            <b-col cols="12">
                <p>Password Baru</p>
                <b-input-group>
                    <b-form-input id="Email" class="input" placeholder="Password Baru" v-model="passBaru" :type="passwordFieldTypeBaru" required></b-form-input>
                    <b-icon v-if="passwordFieldTypeBaru === 'password' " icon="eye" id="show" type="password" @click="switchVisibilityBaru"></b-icon>
                    <b-icon v-if="passwordFieldTypeBaru === 'text' " icon="eye-slash" id="show" type="password" @click="switchVisibilityBaru"></b-icon>
                </b-input-group>
            </b-col>
            <b-col cols="12">
                <p>Konfirmasi Password Baru</p>
                <b-input-group>
                    <b-form-input id="Password" class="input" placeholder="Konfirmasi Password Baru" v-model="passBaru2" :type="passwordFieldTypeBaru2" required></b-form-input>
                    <b-icon v-if="passwordFieldTypeBaru2 === 'password' " icon="eye" id="show" type="password" @click="switchVisibilityBaru2"></b-icon>
                    <b-icon v-if="passwordFieldTypeBaru2 === 'text' " icon="eye-slash" id="show" type="password" @click="switchVisibilityBaru2"></b-icon>
                </b-input-group>
                
                
            </b-col>
        </b-row>
        <b-row class="buttonInput">
            <b-col cols="6">
                <b-button id="kembali" class="button" @click="$bvModal.hide('modal-center')">Kembali</b-button>
            </b-col>
            <b-col cols="6">
                <b-button id="kirim" class="button" variant="danger" @click="gantiPassword">Kirim</b-button>
            </b-col> 
        </b-row>
        <!-- <b-row>
            <b-alert class="alert" v-model="showDismissibleAlert" variant="success" dismissible>
                Password Berhasil diganti !
            </b-alert>
            <b-alert class="alert" v-model="showDismissibleAlert2" variant="danger" dismissible>
                Password Gagal diganti !
            </b-alert>
            <b-alert class="alert" v-model="showDismissibleAlert3" variant="danger" dismissible>
                Password Baru Harus Sama !
            </b-alert>
            <b-alert class="alert" v-model="showDismissibleAlert4" variant="danger" dismissible>
                Password Tidak Boleh Kosong !
            </b-alert>
        </b-row> -->
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
    name: 'GantiPassword',
    data(){
        return{
            email: '',
            passBaru: '',
            passBaru2: '',
            passwordFieldType: "password",
            passwordFieldTypeBaru: "password",
            passwordFieldTypeBaru2: "password",
            // showDismissibleAlert: false,
            // showDismissibleAlert2: false,
            // showDismissibleAlert3: false,
            // showDismissibleAlert4: false
        }
    },
    created(){
        
    },
    methods: {
        checkpass(){
            if(this.passBaru !== this.passBaru2){
                this.$toast.open({
                    message: 'Password Tidak Sama !',
                    type: 'error',
                    position: 'top'
                });
            }
        },
        kembali() {
            this.$root.$emit('methodBack')
        },
        switchVisibilityBaru() {
            this.passwordFieldTypeBaru = this.passwordFieldTypeBaru === "password" ? "text" : "password";
        },
        switchVisibilityBaru2() {
            this.passwordFieldTypeBaru2 = this.passwordFieldTypeBaru2 === "password" ? "text" : "password";
        },
        gantiPassword(){
            const currentUser = firebase.auth().currentUser
            if (this.passBaru !== '' && this. passBaru2 !== ''){
                if (this.passBaru == this.passBaru2){
                    firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        user.updatePassword(this.passBaru).then(() => {
                            const credential = firebase.auth.EmailAuthProvider.credential(
                                currentUser.email,
                                this.passBaru
                            )
                            user.reauthenticateWithCredential(credential);
                            this.passBaru = ''
                            this.passBaru2 = ''
                            this.$toast.open({
                                message: 'Password Berhasil Diganti !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(() => {
                            this.$toast.open({
                                message: 'Password Gagal Diganti !',
                                type: 'error',
                                position: 'top'
                            });
                        }) 
                    }
                    });
                } else {
                    this.$toast.open({
                        message: 'Password Harus Sama !',
                        type: 'error',
                        position: 'top'
                    });
                }
            } else {
                this.$toast.open({
                    message: 'Password Tidak Boleh Kosong !',
                    type: 'error',
                    position: 'top'
                });
            }
        }
    }
}
</script>

<style scoped>
    .password{
        background-image: white;
        max-width: fit-content;
        height: fit-content;
    }
    .input{
        color: black;
    }
    .buttonInput{
        margin-top: 2rem;
    }
    #show{
        margin: auto;
        margin-left: 0.5rem;
        font-size: 1.5rem;
    }
    #kirim{
        width: 100%;
    }
    #kembali{
        width: 100%;
    }
    p{
        padding: 0rem;
        margin: 0rem;
        margin-top: 1rem;
        font-size: 1.1rem;
    }
    .alert{
        margin-top: 1rem;
    }
</style>
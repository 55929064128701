import { render, staticRenderFns } from "./WelcomePage.vue?vue&type=template&id=46c2b397&scoped=true&"
import script from "./WelcomePage.vue?vue&type=script&lang=js&"
export * from "./WelcomePage.vue?vue&type=script&lang=js&"
import style0 from "./WelcomePage.vue?vue&type=style&index=0&id=46c2b397&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c2b397",
  null
  
)

export default component.exports
<template>
    <div class="content">
        <b-row>
            <b-col cols="1" style="margin-left: 1rem; font-family: Oswald" @click="Kembali"> <img src="@/assets/assets/BackImage.png" alt=""></b-col>
            <b-col><h2 style="margin-right: 4rem">Toko</h2></b-col>
        </b-row>
        <div>
            <gmap-map
            :zoom="20"    
            :center="{lat:currentLocation.lat, lng:currentLocation.lng}"
            style="width:100%;  height: 400px;"
            
        >
        
        <gmap-marker
            :position="currentLocation"
            
            
        ></gmap-marker>
        
        </gmap-map>
        </div>
        <b-tabs>
        <!-- List Toko -->
            <div  class="card" v-for="(item,i) in dataToko" :key="i.id" style="font-family: Oswald">
                    <b-row>
                        <b-col>
                            <li class="magnifier" style="margin-left: 1rem; margin-top: 1.5rem" @click="alamat(item.latitude,item.longitude)"><img  src="@/assets/assets/SearchToko.png"></li>
                        </b-col>
                        <b-col cols="6">
                            <div style="text-align: left; margin-top: 1rem; margin-bottom: 0.5rem; margin-left: -1rem" @click="alamat(item.latitude,item.longitude)">
                                <h5 class="card-title" style="font-weight: Bold">{{item.department}}</h5>
                                <p class="card-text" id="tanggalPoin">{{item.alamat}}</p>
                                
                            </div>
                        </b-col>
                        <b-col>
                            <ul class="poin">
                                <a v-if="item.id_department == '00'" href="https://wa.me/6287834888324"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '01'" href="https://wa.me/6282338500421"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '02'" href="https://wa.me/6287834765442"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '03'" href="https://wa.me/6281804051936"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '04'" href="https://wa.me/6287772369030"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '05'" href="https://wa.me/6287734717991"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '06'" href="https://wa.me/6281919936666"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '07'" href="https://wa.me/6287734907267"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '08'" href="https://wa.me/6285865348307"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                                <a v-if="item.id_department == '09'" href="https://wa.me/6287734908389"><li class="totalB" style="margin-right: 0.4rem; margin-top:1.5rem;"><img src="@/assets/assets/WALogo.png"></li></a>
                            </ul>
                        </b-col>
                    </b-row>
                </div>
        
        </b-tabs>
    </div>
</template>

<script>
import axios from "axios"
import firebase from 'firebase/app'
import 'firebase/auth'
import dotenv from "dotenv"
dotenv.config()

export default ({
    name : "Toko",
    data(){
        return{
            dataToko: [],
            currentLocation: {
                lat: 0,
                lng: 0
            },
            markers : [],
            // existingPlace: null,
           
        }
    },
    created(){
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "Toko"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.getToko();
                    }
                } else {
                    this.$router.replace({name : "Login"}).then(() => {
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        localStorage.clear()
                    }).catch(()=>{})
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
        // this.getNum();
    },
    mounted : function(){
        this.locateGeoLocation();
    },
  
    methods:{
        async getToko(){
            console.log(process.env.VUE_APP_toko);
             try{
                 let userDataToken = JSON.parse(localStorage.getItem("apiDataToken"));
                const response = await axios.get(process.env.VUE_APP_toko,{ headers: {"Authorization" : `Bearer ${userDataToken.token}`} });
                this.dataToko = response.data.data;
            //    this.newNumber = this.dataToko.no_ponsel
            //    this.newNumber = test.substr(1) 
            //    console.log(this.newNumber)
            }
            catch(err){
                console.log(err);
            }
        },
        locateGeoLocation: function(){
            navigator.geolocation.getCurrentPosition(res => {
                this.currentLocation = {
                    lat: res.coords.latitude,
                    lng: res.coords.longitude
                }
            })
        },
        alamat(latitude,longitude){
             this.currentLocation= {
                 lat: latitude,
                 lng: longitude
             }
        },
        Kembali(){
            this.$router.replace('Homepage')
        }
    }
})
</script>

<style scoped>
*{
    font-family: Oswald;
}
    .magnifier{
        float: left;
        color: #C12D31;
        list-style: none;
    }
    
</style>

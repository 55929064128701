<template>
    <div class="container">
        <b-card>
            <b-tabs v-model="tabIndex" align="center" active-nav-item-class="toActive" nav-class="backgroundCol">
                <b-tab style="padding-top: 1rem;" title="Identitas">
                    <b-img v-if="blankImage=false" id="image" @change="previewImage" :src="data.gambar" rounded="circle"></b-img>
                    <b-img v-if="blankImage=true" id="blankImage" @change="previewImage" :src="data.gambar" rounded="circle"></b-img>
                    <b-col><input type="file" style="display: none;" ref="input" @change="previewImage" accept="image/*"></b-col>
                    <b-col v-if="stateUpload===1">
                        <p>Uploading: {{uploadValue.toFixed()+"%"}}
                            <progress :value="uploadValue" max="100"></progress>
                        </p>
                    </b-col> 
                    <b-row>
                        <b-col><b-btn id="btn" @click="click" variant="outline-primary">Choose Photo</b-btn></b-col>
                    </b-row>

                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="credit-card-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.ktp" placeholder="Masukkan Nomor KTP / SIM / Kartu Pelajar" :value="biodata.data.ktp" maxlength="16" onkeypress="return event.charCode >= 48 && event.charCode <=57" required></b-form-input></b-col>
                            </b-row>
                            <b-row v-if="alertNIK">
                                <p style="color:red;">Format NIK Salah !</p>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="person-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input v-model="data.nama" placeholder="Nama Lengkap" :value="biodata.data.nama"></b-form-input></b-col>
                            </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="6"><p id="JenisKelamin">Jenis Kelamin</p></b-col>
                                <b-col cols="3"><b-form-radio v-model="data.jns_kelamin" name="some-radios" value="L"><img src="../assets/assets/male-solid.png" alt="" style="width: 2rem;"></b-form-radio></b-col>
                                <b-col cols="3"><b-form-radio v-model="data.jns_kelamin" name="some-radios" value="P"><img src="../assets/assets/female-solid.png" alt="" style="width: 2rem;"></b-form-radio></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="geo-alt-fill"></b-icon></b-col>
                                <b-col cols="10"><b-form-input v-model="data.tempat_lahir" placeholder="Tempat Lahir" :value="biodata.data.tempat_lahir"></b-form-input></b-col>
                            </b-row>  
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="calendar-date"></b-icon></b-col>
                                <b-col cols="10">
                                    <date-picker id="datePicker" v-model="data.tgl_lahir" :masks='{ input:"YYYY-MM-DD"}' :model-config="modelConfig" :available-dates='{
                                        start: new Date(new Date().getFullYear() - 70, 0, 1),
                                        end: new Date(new Date().getFullYear() - 15, 11, 31)
                                    }'>
                                        <template #default="{ inputValue, inputEvents }">
                                            <b-form-input  :value="inputValue" v-on="inputEvents" readonly/>
                                        </template>
                                    </date-picker>
                                </b-col>
                                </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="person-fill"></b-icon></b-col>
                                <!-- <b-col cols="10"><b-form-input placeholder="Agama" :value="this.agama"></b-form-input></b-col> -->
                                <b-col cols="10">
                                    <select name="agama_id" @change="onAgamaChange($event)" class="form-select form-control" v-model="data.agama" placeholder="Agama">
                                        <option value="1">Islam</option>
                                        <option value="2">Katholik</option>
                                        <option value="3">Kristen</option>
                                        <option value="4">Buddha</option>
                                        <option value="5">Hindu</option>
                                        <option value="6">Kong Hu Cu</option>
                                        <option value="7">Lainnya</option>
                                    </select>
                                </b-col>
                            </b-row>  
                        </div> 
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="briefcase"></b-icon></b-col>
                                <b-col cols="10">
                                    <select name="pekerjaan_id" @change="onPekerjaanChange($event)" class="form-select form-control" v-model="data.pekerjaan">
                                        <option value="1">Ibu Rumah Tangga</option>
                                        <option value="2">Profesional</option>
                                        <option value="3">TNI / POLRI</option>
                                        <option value="4">Guru / Dosen</option>
                                        <option value="5">Karyawan / Staff BUM Swasta</option>
                                        <option value="6">Buruh</option>
                                        <option value="7">Pensiunan / Purnawirawan</option>
                                        <option value="8">Pegawai Negri Sipil</option>
                                        <option value="9">Pelajar / Mahasiswa</option>
                                        <option value="10">Wiraswasta</option>
                                        <option value="11">Belum / Tidak Bekerja</option>
                                        <option value="12">Karyawan / Staff BUMN / BUMD</option>
                                        <option value="13">Pejabat Pemerintah</option>
                                        <option value="14">Rohaniawan</option>
                                        <option value="15">Seniman</option>
                                    </select>
                                </b-col>
                            </b-row>  
                        </div> 
                    </div>
                    <b-button id="simpan" class="button" @click="cekIdentitas">Lanjut</b-button>
                    <b-button id="kembali" class="button" @click="kembali">Kembali</b-button> 
                </b-tab>
                <b-tab style="padding-top: 1rem;" title="Kontak" :disabled="stateKontak">
                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="envelope"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.email" placeholder="Email" :value="biodata.data.email" readonly></b-form-input></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="phone"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.ponsel" placeholder="Nomor Ponsel" :value="biodata.data.ponsel" minlength="11" maxlength="12" onkeypress="return event.charCode >= 48 && event.charCode <=57"></b-form-input></b-col>
                            </b-row>
                            <b-row v-if="alertNomor">
                                <p style="color:red;">Format Nomor Telepon Salah !</p>
                            </b-row>
                        </div>  
                    </div>
                    <b-button id="simpan" class="button" @click="cekKontak">Lanjut</b-button>
                    <b-button id="kembali" class="button" @click="tabIndex--">Kembali</b-button>
                </b-tab>
                <b-tab style="padding-top: 1rem;" title="Alamat" :disabled="stateAlamat">
                    <div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="house"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="data.alamat" placeholder="Alamat Lengkap" :value="biodata.data.alamat"></b-form-input></b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Provinsi</p></b-col>
                                <b-col cols="9">
                                    <select name="provinsi" @change="onProvinsiChange($event)" class="form-select form-control" v-model="provinsi" @click="getProvinsi">\
                                        <option v-for="(prov, i) in dataProvinsi" :key="i.id" :value=dataProvinsi[i].provinsi>{{dataProvinsi[i].provinsi}}</option>
                                        <!-- <option v-for="(prov, i) in dataProvinsi" :key="i.id" :value=dataProvinsi[i].provinsi>{{dataProvinsi[i].provinsi}}</option> -->
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kabupaten</p></b-col>
                                <b-col cols="9">
                                    <select name="kabupaten" @change="onKabupatenChange($event)" class="form-select form-control" v-model="kabupaten" @click="getKabupaten">
                                        <option v-for="(prov, i) in dataKabupaten" :key="i.id" :value=dataKabupaten[i].kabupaten>{{dataKabupaten[i].kabupaten}}</option>
                                        <!-- <option v-for="(kab, i) in dataKabupaten" :key="i.id" :value=dataKabupaten[i].kabupaten>{{dataKabupaten[i].kabupaten}}</option> -->
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kecamatan</p></b-col>
                                <b-col cols="9">
                                    <select name="kabupaten" @change="onKecamatanChange($event)" class="form-select form-control" v-model="kecamatan" @click="getKecamatan">
                                        <option v-for="(prov, i) in dataKecamatan" :key="i.id" :value=dataKecamatan[i].kecamatan>{{dataKecamatan[i].kecamatan}}</option>
                                        <!-- <option v-for="(kec, i) in dataKecamatan" :key="i.id" :value=dataKecamatan[i].kecamatan>{{dataKecamatan[i].kecamatan}}</option> -->
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3"><p style="margin: 0rem; padding-top: 0.4rem">Kelurahan</p></b-col>
                                <b-col cols="9">
                                    <select name="kelurahan" @change="onKelurahanChange($event)" class="form-select form-control" v-model="kelurahan" @click="getKelurahan">\
                                        <option v-for="(prov, i) in dataKelurahan" :key="i.id" :value=dataKelurahan[i].kelurahan>{{dataKelurahan[i].kelurahan}}</option>
                                        <!-- <option v-for="(kel, i) in dataKelurahan" :key="i.id" :value=dataKelurahan[i].kelurahan>{{dataKelurahan[i].kelurahan}}</option> -->
                                    </select>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="input">
                            <b-row>
                                <b-col cols="2"><b-icon class="icon" icon="house"></b-icon></b-col>
                                <b-col cols="10"><b-form-input id="card" v-model="kodepos" placeholder="Kode Pos" :value="kodepos" readonly></b-form-input></b-col>
                            </b-row>
                        </div>
                    </div>
                    <b-button id="simpan" class="button" @click="cekAlamat">Simpan</b-button>
                    <b-button id="kembali" class="button" @click="tabIndex--">Kembali</b-button>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import dotenv from "dotenv"
dotenv.config()

export default {
    name: 'Pendaftaran',
    components: {
        DatePicker
    },
    data(){
        return {
            biodata: [],
            data: {
                "migrasi": null,
                "ktp": "",
                "nama": "",
                "alamat": "",
                "tempat_lahir": "",
                "tgl_lahir": "",
                "jns_kelamin": "",
                "agama": "",
                "kd_lokasi": null,
                "id_kodepos": 0,
                "pekerjaan": "",
                "status_nikah": null,
                "telp": null,
                "ponsel": "",
                "email": "",
                "status": null,
                "user_entry": "999999999",
                "tgl_pemutihan": null,
                "user_pemutihan": null,
                "saldonetbon": null,
                "poin": null,
                "gambar": "",
                "firebase_uid": "",
                "token_device": ""
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            
            kodepos:'',
            stateKontak:true,
            stateAlamat:true,

            dataProvinsi: [],
            dataKabupaten: [],
            dataKecamatan: [],
            dataKelurahan: [],

            provinsi: '',
            kabupaten: '',
            kecamatan: '',
            kelurahan: '',

            blankImage: true,

            imageData: null,
            picture: null,
            uploadValue: 0,
            stateUpload: 0,

            // showDismissibleAlert: false,
            // showDismissibleAlert2: false,

            onAgamaChange(e) {
                this.data.agama = e.target.value
            },
            onPekerjaanChange(e) {
                this.data.pekerjaan = e.target.value
            },
            onProvinsiChange(e) {
                this.provinsi = e.target.value
            },
            onKabupatenChange(e) {
                this.kabupaten = e.target.value
            },
            onKecamatanChange(e) {
                this.kecamatan = e.target.value
            },
            onKelurahanChange(e) {
                this.kelurahan = e.target.value
            },
            tabIndex:0,
            token: '',
            userUID: '',

            alertNIK: false,
            alertNomor: false
        }
    },
    created() {
        this.getBiodata()
        this.getToken()
        this.checkState()
        this.getProvinsi()
        this.getAuth()
    },
    mounted() {
        this.$nextTick(function () {
            window.setInterval(() => {
                this.nextToKontak();
                this.nextToAlamat();
                this.uploadState();
            },100);
        })
    },
    methods: { 
        uploadState() {
            if(this.uploadValue !== 0 && this.uploadValue !== 100){
                this.stateUpload = 1
            }else{
                this.stateUpload = 0
            }
        },
        click() {
            this.$refs.input.click()
        },
        previewImage(event) {
            this.uploadValue=0;
            this.picture=null;
            this.imageData=event.target.files[0];
            this.onUpload()
        },
        onUpload(){
            this.picture=null
            const storageRef = firebase.storage().ref(`data/user/0/id.amigogroup.member/cache/${this.imageData.name}`).put(this.imageData)
            storageRef.on(`state_changed`, snapshot => {
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error => {console.log(error.message)},
                () => {this.uploadValue=100;
                storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                    this.picture=url
                    this.data.gambar=url
                })
                }
            )
        },
        async getAuth() {
            firebase.auth().onAuthStateChanged(
                (user) => {
                    this.firebase_uid = user.uid
                }
            )
        },
        async getToken() {
            firebase.auth().onAuthStateChanged(
                // eslint-disable-next-line no-unused-vars
                async (user) => {
                    await firebase.auth().currentUser.getIdTokenResult().then(
                        (token) => {
                            this.token = token.token;
                            this.data.token_device = token.token;
                            localStorage.setItem("apiDataToken", JSON.stringify(token))
                            localStorage.setItem("tokenDevice", JSON.stringify(token.token))
                    });
                }
            )
        },
        getBiodata() {
            this.biodata = JSON.parse(localStorage.getItem("apiData"))
            this.data.ktp = this.biodata.data.ktp
            this.data.nama = this.biodata.data.nama
            this.data.alamat = this.biodata.data.alamat
            this.data.tempat_lahir = this.biodata.data.tempat_lahir
            this.data.tgl_lahir = this.biodata.data.tgl_lahir
            this.data.jns_kelamin = this.biodata.data.jns_kelamin
            this.data.agama = this.biodata.data.agama
            this.data.pekerjaan = this.biodata.data.pekerjaan
            this.data.email = this.biodata.data.email
            this.data.ponsel = this.biodata.data.ponsel
            this.data.firebase_uid = this.biodata.data.firebase_uid
            this.data.gambar = this.biodata.data.gambar
        },
        kembali() {
            this.$router.replace({name : "WelcomePage"}).catch(()=>{})
        },
        nextToKontak() {
            if(this.data.ktp !== "" && this.data.nama !== "" && this.data.jns_kelamin !== "" && this.data.tempat_lahir !== "" && this.data.tgl_lahir !== "" && this.data.agama !== "" && this.data.pekerjaan !== ""){
                if(this.data.ktp.length === 16){
                    this.stateKontak=false
                    this.alertNIK=false
                }else{
                    this.alertNIK=true
                    this.stateKontak=true
                    this.stateAlamat=true
                }
            }else{
                this.stateKontak=true
                this.stateAlamat=true
            }
        },
        nextToAlamat() {
            if(this.data.ponsel !== "" && this.data.ktp !== "" && this.data.ktp.length === 16 && this.data.nama !== "" && this.data.tempat_lahir !== ""){
                if(this.data.ponsel.length === 11 || this.data.ponsel.length === 12){
                    this.stateAlamat=false
                    this.alertNomor=false
                }else{
                    this.alertNomor=true
                    this.stateAlamat=true
                }
            }else{
                this.stateAlamat=true
            }
        },
        cekIdentitas() {
            this.tabIndex=1
        },
        cekKontak() {
            this.tabIndex=2
        },
        cekAlamat() {
            if(this.data.ktp !== "" && this.data.nama !== "" && this.data.jns_kelamin !== "" && this.data.tempat_lahir !== "" && this.data.tgl_lahir !== "" && this.data.agama !== "" && this.data.pekerjaan !== "" && this.data.ponsel !== "" && this.data.alamat !== "" && this.alertNIK !== true && this.alertNomor !== true){
                // eslint-disable-next-line no-unused-vars
                const res = axios.post(process.env.VUE_APP_cekAlamat, this.data, { headers: {"Authorization" : `Bearer ${this.token}`} })
                .then(res => {
                    localStorage.setItem("apiData", JSON.stringify(res))
                    let userData = JSON.parse(localStorage.getItem("apiData"));
                    this.$toast.open({
                        message: 'Data Telah Terisi Semua, Pendaftaran Berhasil !',
                        type: 'success',
                        position: 'top'
                    });
                    axios.post(`${process.env.VUE_APP_welcomeVoucher}${userData.data.alias}`, {}, { headers: {"Authorization" : `Bearer ${this.token}`} })
                    .then(() => {
                        console.log('Welcome Voucher Sent');
                        this.$router.replace({name : "Homepage"})
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                            position: 'top'
                        });
                    })
                })
                .catch((error) => {
                    if (error.request.status !== 400) {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                            position: 'top'
                        });
                    } else if (error.request.status == 400) {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                            position: 'top'
                        });
                    }
                })
            } else {
                this.$toast.open({
                    message: 'Ada Data yang Masih Kosong, Pendaftaran Gagal !',
                    type: 'error',
                    position: 'top'
                });
            }
        },
        checkState() {
            if(localStorage.getItem("auth")==='true'){
                this.stateKontak = false
                this.stateAlamat = false
            }
        },
        getProvinsi() {
            //eslint-disable-next-line no-unused-vars
            const res = axios.get(`${process.env.VUE_APP_regisProvinsi}`,{ headers: {"Authorization" : `Bearer ${this.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataProvinsi = data.data
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        getKabupaten() {
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_regisKabupaten}${this.provinsi}`,{ headers: {"Authorization" : `Bearer ${this.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKabupaten = data.data
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        getKecamatan() {
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_regisKecamatan}${this.kabupaten}`,{ headers: {"Authorization" : `Bearer ${this.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKecamatan = data.data
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        getKelurahan() {
            let array = '0'
            //eslint-disable-next-line no-unused-vars
            const res =  axios.get(`${process.env.VUE_APP_regisKelurahan}${this.kecamatan}`,{ headers: {"Authorization" : `Bearer ${this.token}`} })
            .then((res) => {
                if(res.status === 200){
                    const data = JSON.parse(JSON.stringify(res))
                    this.dataKelurahan = data.data
                    for(let i = this.dataKelurahan.length-1; i>=0; i--){
                        if(this.dataKelurahan[i].kelurahan === this.kelurahan){
                            array = i
                        }
                    }
                    this.data.id_kodepos = this.dataKelurahan[array].id
                    this.kodepos = this.dataKelurahan[array].kodepos
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>
    header{
        margin-bottom: 10%;
    }
    b-form-input{
        margin-left: 1%;
    }
    .icon{
        font-size: 2rem;
        margin: 2%;
    }
    .container{
        margin: auto;
        text-align: center;
        width: 100%;
    }
    .header1{
        color: white;
        background-color: darkred;
        padding: 2%;
        margin-bottom: 2%;
    }
    .header2{
        padding-bottom: 3%;
        border-bottom: 2px solid black;
    }
    .input{
        margin: 2%;
    }
    .gender{
        float: right;
    }
    .button{
        float: right;
        margin: 2%;
        padding: 1%;
    }
    #JenisKelamin{
        float: left;
        margin-top: 10%;
    }
    #back{
        margin-top: 1%;
        margin-left: 5%;
        position: absolute;
        left: 2%;
        color: white;
        font-size: 1.5rem;
    }
    #iconPict{
        font-size: 7rem;
    }
    #JenisKelamin{
        margin-top: 15%;
    }
    #simpan{
        background-color: darkred;
        border-style: none;
    }
    #kembali{
        color: black;
        background-color: transparent;
        border-style: none;
    }
    #image{
        width: 10rem;
        height: 10rem;
        padding-bottom: 0;
        object-fit: cover;
    }
    #blankImage{
        width: 10rem;
        height: 10rem;
        background: grey;
        padding-bottom: 0;
        object-fit: cover;
    }
    #tglLahir{
        width: 100%;
    }
</style>
<template>
    <div class="container">
        <b-row>
            <b-row id="userInput">
                <b-col cols="2">
                    <b-icon icon="envelope" style="color: white; margin-top: 40%;"></b-icon>
                </b-col>
                <b-col cols="10">
                    <b-form-input  id="email" for="email" class="input" v-model="email" type="email" placeholder="Email" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">
                    <b-icon icon="lock" style="color: white; margin-top: 40%;"></b-icon>
                </b-col>
                <b-col cols="9">
                    <b-form-input id="password" class="input" v-model="password" :type="passwordFieldType" placeholder="Enter password" required></b-form-input>
                </b-col>
                <b-col cols="1">
                    <b-icon v-if="state" icon="eye" id="show" type="password" @click="switchVisibility"></b-icon>
                    <b-icon v-if="!state" icon="eye-slash" id="show" type="password" @click="switchVisibility"></b-icon>
                </b-col>
            </b-row>
        </b-row>
            <button id="forgetPass" v-b-modal.modal-center>lupa password?</button>
            <b-modal id="modal-center" centered size="sm" hide-footer hide-header>
                <lupaPassword />
            </b-modal>
        <b-row>
            <b-button id="masuk" class="button" variant="danger" @click="login">Masuk</b-button>
            <b-button id="google" class="button" @click="loginGoogle">Masuk dengan Google</b-button>
        </b-row> 
        <b-row>
            <a href="https://play.google.com/store/apps/details?id=id.amigogroup.member"><img src="../assets/assets/logo_play_store.png" alt="" id="playstore"> </a>
        </b-row>     
        <b-row id="daftar">
            <p @click="register">Belum memiliki AMIGO MEMBER?<button id="btnDaftar">DAFTAR</button> Sekarang </p>   
        </b-row>  
    </div>
</template>
 
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import axios from "axios"
import lupaPassword from './LupaPassword.vue'
import dotenv from "dotenv"
dotenv.config()

export default {
    name: 'Login',
    components:{
        lupaPassword
    },
    data() {
        return {
            email: '',
            password: '',
            passwordFieldType: "password",
            token: '',
            state: false,
            auth: false
        }
    },
    mounted() {
        this.$root.$on('methodBack', () => {
            this.back()
        })
    },
    created() {
        this.auth = false
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            if(localStorage.getItem('apiData') != "."){
                firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        if(firebase.auth().currentUser.emailVerified==true){
                            this.$router.replace({name : "Homepage"}).then(() => {
                                this.$toast.open({
                                    message: 'Selamat Datang Kembali !',
                                    type: 'success',
                                    position: 'top'
                                });
                            }).catch(()=>{})
                        }
                    } else {
                        this.$router.replace({name : "Login"}).then(() => {
                            this.$toast.open({
                                message: 'Harap Login Kembali !',
                                type: 'error',
                                position: 'top'
                            });
                            localStorage.clear()
                        }).catch(()=>{})
                    }
                });
            } else {
                localStorage.clear()
            }
        } else {
            this.$router.replace({name : "Login"}).then(() => {
                this.$toast.open({
                    message: 'Session Anda Sudah Berakhir, Harap Login Kembali!',
                    type: 'error',
                    position: 'top'
                });
            }).catch(()=>{})
        }
    },
    methods: {
        back() {
            this.$bvModal.hide('modal-center')
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            if(this.passwordFieldType === 'text'){
                this.state = true
            }else if(this.passwordFieldType === 'password'){
                this.state = false
            }
        },
        async login() {
            if(this.email !== "" && this.password !== ""){ //cek email dan password kosong atau tidak
                if(localStorage.getItem('apiData') == null){ //jika kosong melakukan pengecekan dan get data user baru
                    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                    .then(() => {
                        firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
                            // eslint-disable-next-line no-unused-vars
                            (user) => {
                                this.getIsVerif()
                            },
                            // eslint-disable-next-line no-unused-vars
                            (error) => {
                                // this.showDismissibleAlert2 = true
                                this.$toast.open({
                                    message: 'Email atau Password Salah !',
                                    type: 'error',
                                    position: 'top'
                                });
                            }
                        );
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-unused-vars
                        var errorCode = error.errorCode
                        // eslint-disable-next-line no-unused-vars
                        var errorMessage = error.message
                    })
                } else if (localStorage.getItem('apiData')){ //jika LocalStorage sudah ada isinya langsung lempar ke Homepage
                    this.$router.replace({name : "Homepage"}).catch(()=>{})
                }
                
            }else{
                this.$toast.open({
                    message: 'Email atau Password Tidak Boleh Kosong !',
                    type: 'error',
                    position: 'top'
                });
            }  
        },
        async getIsVerif(){
            if(firebase.auth().currentUser.emailVerified===true){
                await this.getToken()
            }else if(firebase.auth().currentUser.emailVerified===false){
                firebase.auth().currentUser.sendEmailVerification()
                this.$toast.open({
                    message: 'Harap Melakukan Verifikasi Email Terlebih Dahulu !',
                    type: 'error',
                    position: 'top'
                });
                this.auth = false
                localStorage.clear()
                this.$router.replace({name : "Login"}).catch(()=>{})
            }else{
                this.$toast.open({
                    message: 'Harap Melakukan Verifikasi Email Terlebih Dahulu !',
                    type: 'error',
                    position: 'top'
                });
            }
        },
        async getToken() {
            await firebase.auth().currentUser.getIdTokenResult().then(
                (token) => {
                    this.token = token.token;
                    localStorage.setItem("apiDataToken", JSON.stringify(token))
                    this.getItems()
            });
        },
        async getItems() {
            firebase.auth().onAuthStateChanged( //cek data ada atau tidak
                async (user) => {
                    if(user){
                        const uid = user.uid;
                        // eslint-disable-next-line no-unused-vars
                        const res = await axios.get(`${process.env.VUE_APP_getItems}${uid}`, { headers: {"Authorization" : `Bearer ${this.token}`} })
                        .then((res) => {
                            if(res.status === 200){
                                this.auth = true

                                //LocalStorage
                                localStorage.setItem("auth", this.auth)
                                localStorage.setItem("apiData", JSON.stringify(res))
                                localStorage.setItem("firebaseUID", JSON.stringify(uid))
                                localStorage.setItem("tokenDevice", JSON.stringify(res.data.token_device))

                                if(localStorage.getItem('apiData')){
                                    this.$router.replace({name : "Homepage"}).catch(()=>{})
                                }
                            }else{
                                this.$router.replace({name : "Login"}).catch(()=>{})
                            }
                        })
                        .catch((error) => {
                            if (error.request.status === 404) {
                                localStorage.setItem("auth", this.auth)
                                this.$router.replace({name : "WelcomePage"}).catch(()=>{})
                            }
                        })
                    }
                },
            );
        },
        register() {
            this.$router.replace('Register')
        },
        loginGoogle() {
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider).then(
                // eslint-disable-next-line no-unused-vars
                (result) => {
                    this.getIsVerif()
                }
            )
            .catch((err) => {
                console.log(err.message)
            })
        }
    },
}
</script>

<style scoped>
    .container{
        position: relative;
        background-image: url('../assets/assets/AmigoBg.png');
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .input{
        color: white !important;
        margin: 2% 0% 0% 0%;
        background-color:transparent !important;
        border:none;
        border-bottom: 1px white solid;
        box-shadow:none !important;
    }
    .input::placeholder{
        color: white;
        background: transparent;
    }
    .button{
        margin: auto;
        margin-left: 10%;
        width: 80%;
    }
    .alert{
        color: darkred;
        background-color: rgba(255, 255, 255, 0.5);
        border: none;
    }
    #userInput{
        margin-top: 50%;
    }
    #masuk{
        margin-top: 20%;
        margin-bottom: 2%;
        border-color: white;
    }
    #google{
        color: white;
        background: transparent;
        border-color: white;
    }
    #show{
        font-size: 150%;
        margin-top: 1rem;
        color: white;
    }
    #forgetPass{
        color: white;
        position: absolute;
        right: 0px;
        background: transparent;
        border-style: none;
    }
    #daftar{
        color: white;
        font-size: 14px;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0px;
    }
    #btnDaftar{
        color: white;
        background-color: transparent;
        border-style: none;
    }
    #playstore{
        
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
</style>
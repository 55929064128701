<template>
   <div class="container">
        <b-row>
            <b-col cols="1"><b-icon id="back" icon="arrow-left-circle" @click="kembali"></b-icon></b-col>
            <b-row id="userInput">
                <h1>Buat Akun Baru</h1>
                <b-col cols="2">
                    <b-icon icon="envelope" style="color: white; margin-top: 40%;"></b-icon>
                </b-col>
                <b-col cols="8">
                    <b-form-input  id="email" for="email" class="input" v-model="email" type="email" placeholder="Enter email" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">
                    <b-icon icon="lock" style="color: white; margin-top: 40%;"></b-icon>
                </b-col>
                <b-col cols="8">
                    <b-form-input id="password" class="input" v-model="password" :type="passwordFieldType" placeholder="Enter password" required></b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-icon v-if="state" icon="eye" id="show" type="password" @click="switchVisibility"></b-icon>
                    <b-icon v-if="!state" icon="eye-slash" id="show" type="password" @click="switchVisibility"></b-icon>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">
                    <b-icon icon="lock" style="color: white; margin-top: 40%;"></b-icon>
                </b-col>
                <b-col cols="8">
                    <b-form-input id="passwordConfirm" class="input" v-model="passwordConfirm" :type="passwordFieldType" placeholder="Confirm password" required></b-form-input>
                </b-col>
            </b-row>
            <b-button id="daftar" class="button" variant="danger" @click="signUp">Daftar</b-button>
        </b-row>  
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
    name: 'Register',
    data() {
      return {
          email: '',
          password: '',
          passwordConfirm: '',
          passwordFieldType: "password",
          state: false
      }
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            if(this.passwordFieldType === 'text'){
                this.state = true
            }else if(this.passwordFieldType === 'password'){
                this.state = false
            }
        },
        signUp() {
            firebase
                .auth()
                .createUserWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    const user = firebase.auth().currentUser;
                    user.sendEmailVerification();
                    this.email = ''
                    this.password = ''
                    this.passwordConfirm = ''
                    this.$toast.open({
                        message: 'Pendaftaran Berhasil, Cek Email Anda untuk Verifikasi !',
                        type: 'success',
                        position: 'top'
                    });
                    if(firebase.auth().currentUser.emailVerified==false){
                        localStorage.clear()
                        this.$router.push({name : "Login"})
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch((error) => {
                    this.$toast.open({
                        message: error.message,
                        type: 'error',
                        position: 'top'
                    });
                })
        },
        kembali() {
            localStorage.clear()
            this.$router.push({name : "Login"})
        }
    },
    beforeCreate(){
        localStorage.setItem("auth", ".")
        localStorage.setItem("apiData", ".")
        localStorage.setItem("firebaseUID", ".")
        localStorage.setItem("tokenDevice", ".")
        localStorage.setItem("apiDataToken", ".")
    },
}
</script>

<style scoped>
    .container{
        position: relative;
        background-image: url('../assets/assets/AmigoBg.png');
        width: 100%;
        height: 100%;
        background-size: cover;
        text-align: center;
    }
    .input{
        color: white !important;
        margin: 2% 0% 0% 0%;
        background-color:transparent !important;
        border:none;
        border-bottom: 1px white solid;
        box-shadow:none !important;
    }
    .input::placeholder{
        color: white;
        background: transparent;
    }
    .button{
        margin: auto;
        margin-left: 10%;
        width: 80%;
    }
    #userInput{
        margin-top: 50%;
    }
    #daftar{
        margin-top: 10%;
        margin-bottom: 2%;
        border-color: white;
    }
    #show{
        margin-top: 40%;
        color: white;
    }
    #forgetPass{
        color: white;
        position: absolute;
        right: 0px;
        background: transparent;
        border-style: none;
    }
    #back{
        margin-top: 2%;
        margin-left: 2%;
        position: absolute;
        left: 0px;
        color: white;
        font-size: 2rem;
    }
    h1{
        color: white;
    }
</style>